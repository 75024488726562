import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import banner from "../../../staticAssets/Horoscope Bg.png";
import Leo from "../../../staticAssets/Leo.png";
import Aries from "../../../staticAssets/Aries.png";
import Taurus from "../../../staticAssets/Taurus.png";
import Gemini from "../../../staticAssets/Gemini.png";
import Cancer from "../../../staticAssets/Cancer.png";
import Virgo from "../../../staticAssets/Virgo.png";
import Libra from "../../../staticAssets/Libra.png";
import Scorpio from "../../../staticAssets/Scorpio.png";
import Capricorn from "../../../staticAssets/Capricorn.png";
import Aquarius from "../../../staticAssets/Aquarius.png";
import Pisces from "../../../staticAssets/Pisces.png";
import Sagittarius from "../../../staticAssets/Sagittarius.png";
import day from "../../../staticAssets/day.png";
import month from "../../../staticAssets/month.png";
import year from "../../../staticAssets/year.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { AnalyticsContext } from "../../../context/Analytics";

import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet-async";

function Index({location}) {
  const { site } = useStaticQuery(
    graphql`
      query  {
        site {
          siteMetadata {
            seos {
              title
              description
              canonicalUrl
              path
              _xv
            }
          }
        }
      }
    `
  );
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Helmet>
        <meta
          name="title"
          content={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.title
          }`}
        />
        <meta
          name="description"
          content={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.description
          }`}
        />
        <link
          rel="canonical"
          href={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.canonicalUrl
          }`}
        />
      </Helmet>

      <Header />
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              राशिफल
              </h1>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="container mx-auto flex flex-wrap my-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer ml-4"
              >
                <h4 className="text-orange-600 text-sm mx-1">Bodhi</h4>
              </a>
              <h4 className="mx-1 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/hindi/rashifal/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm mx-1">Rashifal</h4>
              </a>
            </div>
            <div className="container mx-auto my-10">
              <h6 className="tracking-wide mx-4">
              बोधि में हम आपको आपके जीवन में सकारात्मक कदम उठाने में सहायता करने के लिए विभिन्न राशिफल प्रदान कर रहे हैं। हमारे दिए गए सभी राशिफल विश्व के शीर्ष और प्रसिद्ध ज्योतिषियों द्वारा आपके लिए दिए गए समय पर ब्रह्मांडीय संरेखण को देखते हुए बनाए गए हैं। उन्हें पढ़ने से, आपको चुनौतियों और आपके सामने आने वाले अवसरों के बारे में एक स्पष्ट दृष्टिकोण मिलता है, और इसका उत्तर मिलता है कि उनसे कैसे निपटा जाए और परिस्थितियों का अधिकतम लाभ उठाया जाए। बोधि पर ज्योतिषियों से परामर्श करके और भी अधिक व्यक्तिगत मार्गदर्शन प्राप्त करें।
              </h6>
            </div>
            <h4 className="text-[25px] md:text-[36px] text-center font-semibold text-black playfair my-10">
            अपनी राशि के बारे में जानें
            </h4>
            <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-10 work-sans">
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/mesh')}}>
                <img src={Aries} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मेष</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Mar 21 - Apr 19
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/vrishabh')}}>
                <img src={Taurus} className="h-40 w-40 object-contain" />
                <h6 className="text-black">वृषभ</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Apr 20 - May 20
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/mithun')}}>
                <img src={Gemini} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मिथुन</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  May 21 - Jun 21
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/kark')}}>
                <img src={Cancer} className="h-40 w-40 object-contain" />
                <h6 className="text-black">कर्क</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Jun 22 - Jul 22
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/sinh')}}>
                <img src={Leo} className="h-40 w-40 object-contain" />
                <h6 className="text-black">सिंह</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Jul 23 - Aug 22
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/kanya')}}>
                <img src={Virgo} className="h-40 w-40 object-contain" />
                <h6 className="text-black">कन्या</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Aug 23 - Sep 22
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/tula')}}>
                <img src={Libra} className="h-40 w-40 object-contain" />
                <h6 className="text-black">तुला</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Sep 23 - Oct 23
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/dhanu')}}>
                <img src={Sagittarius} className="h-40 w-40 object-contain" />
                <h6 className="text-black">धनु</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Oct 24 - Nov 21
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/vrishchikx')}}>
                <img src={Scorpio} className="h-40 w-40 object-contain" />
                <h6 className="text-black">वृश्चिक</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Nov 22 - Dec 21
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/makar')}}>
                <img src={Capricorn} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मकर</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Dec 22 - Jan 19
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/kumbh')}}>
                <img src={Aquarius} className="h-40 w-40 object-contain" />
                <h6 className="text-black">कुंभ</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Jan 20 - Feb 18
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/daily-rashifal/meen')}}>
                <img src={Pisces} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मीन</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Feb 19 - Mar 20
                </h6>
              </a>
            </div>

            <div className="bg-[#F3F5FA] my-20">
              <div className="container mx-auto w-screen overflow-x-clip">
                <h5 className="mx-4 text-black text-[25px] md:text-[36px] playfair text-center my-10">
                आपकी विशेष भविष्यवाणी
                </h5>
                <div className="flex gap-6  overflow-x-scroll py-10 hide-scroll-bar scrollbar-hide">
                  <div className="flex flex-nowrap">
                    <div className="flex flex-col bg-white shadow-xl rounded-xl p-4 relative w-[70vw] md:w-[26vw] mx-4 ml-10">
                      <div className="absolute -top-6 left-6 p-3 rounded-full border border-gray-100 bg-white">
                        <img src={day} className="h-8 w-8 " />
                      </div>
                      <h6 className="mt-8 mb-6">
                      आज के लिए आपके अवसर और खतरे कहां हैं, इसकी त्वरित झलक पाने के लिए बोधि का दैनिक राशिफल पढ़ें।...
                      </h6>
                      <a className="cursor-pointer text-[#FB5B1D] flex absolute bottom-2 right-3 items-center" href="/hindi/rashifal/daily-rashifal/">
                        और पढ़ें{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-1 h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="flex flex-col bg-white shadow-xl rounded-xl p-4 relative w-[70vw] md:w-[26vw] mx-4">
                      <div className="absolute -top-6 left-6 p-3 rounded-full border border-gray-100 bg-white">
                        <img src={month} className="h-8 w-8 " />
                      </div>
                      <h6 className="mt-8 mb-6">
                      ये है बोधि का साप्ताहिक राशिफल। हमारे शीर्ष ज्योतिषी थिंक वीक के बारे में अंतर्दृष्टि साझा करेंगे जो आपको बढ़त देगा!...
                      </h6>
                      <a className="cursor-pointer text-[#FB5B1D] flex absolute bottom-2 right-3 items-center" href="/hindi/rashifal/weekly-rashifal/">
                        और पढ़ें{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-1 h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="flex flex-col bg-white shadow-xl rounded-xl p-4 relative w-[70vw] md:w-[26vw] mx-4">
                      <div className="absolute -top-6 left-6 p-3 rounded-full border border-gray-100 bg-white">
                        <img src={year} className="h-8 w-8 " />
                      </div>
                      <h6 className="mt-8 mb-6">
                      आपके लिए बोधि की मासिक भविष्यवाणियों में आपका स्वागत है! हमारे शीर्ष ज्योतिषियों द्वारा बनाया गया, यह मासिक राशिफल आपके...
                      </h6>
                      <a className="cursor-pointer text-[#FB5B1D] flex absolute bottom-2 right-3 items-center" href="/hindi/rashifal/monthly-rashifal">
                        और पढ़ें{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-1 h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mx-auto mb-20">
              <h6 className="playfair text-[25px] md:text-[36px] text-center text-black mb-10">
              राशिफल के बारे में
              </h6>
              <h6 className="mx-4">
              कुंडली ज्योतिष और संबंधित भविष्यवाणियों का मूल होने के कारण, एक विशिष्ट समय और स्थान पर ग्रहों की वर्तमान स्थिति के आधार पर गणितीय भविष्यवाणियों पर निर्भर करती है, यह वास्तव में एक स्पष्ट तस्वीर है कि जन्म के समय ग्रहों को कैसे संरेखित किया जाता है। वे बृहत पाराशर होरास्त्र के शास्त्रों पर आधारित हैं। ये राशिफल उपयोगकर्ताओं को किसी भी गतिविधि के संचालन के लिए सही समय सीमा खोजने में मदद करते हैं, चाहे वह व्यवसाय, विवाह या कोई अन्य शुभ अवसर हो। उपयोगकर्ताओं के लिए अधिक व्यक्तिगत भविष्यवाणियां प्राप्त करने के लिए, ज्योतिषी जन्म कुंडली से परामर्श करते हैं, जो जन्म के समय ग्रहों का एक तैयार आधार संरेखण है, या प्रश्न कुंडली, जो प्रश्नों के समय पर आधारित है। व्यक्तिगत मार्गदर्शन के लिए अब बोधि पर ज्योतिषियों से बात करें!
              </h6>
            </div>
          </div>
        <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
